import React, {useEffect} from 'react';

const FileDisplay = (props) => {
	useEffect(() => {
		window.location.href = props.file;
	}, []);

	return null;
};

export default FileDisplay;
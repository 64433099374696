import Landing from "./components/landing";
import FileDisplay from "./components/fileDisplay";
import fileDirectory from './data/index.json';

const getRoutes = () => {
	let routes = [];
	fileDirectory.directories.forEach(dir => {
		routes.push(...dir.files.map(file => {
			return {
				path: (dir.name + '/' + file),
				element: <FileDisplay file={dir.name + '/' + file}/>
			}
		}))
	})
	return routes;
};

const Routes = [
	{path: '/', element: <Landing/>},
	...getRoutes()
];

export default Routes;